import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import setting from '../../config/setting';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Input,
  notification,
  Row,
  Card,
  Space,
  Spin,
  Modal,
  Select,
  Popover
} from 'antd';
import moment from 'moment';
import { CheckSquareTwoTone } from '@ant-design/icons/lib';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGridDetail } from '@grapecity/wijmo.react.grid.detail';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FolderOpenTwoTone } from '@ant-design/icons/lib';

const config = require('../keyFront');
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;
const buttonWidth = {
  width: '60px'
};
class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: _formatExcelItem
      },
      'schedule_events.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => _formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const TestingEventsView = () => {
  let history = useHistory();
  const [testEvents, setTestEvents] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showHideAdvFilter, setShowHideAdvFilter] = useState([]);
  const [testEventTable, setTestEventTableData] = useState([]);
  const [testEventTableBKP, setTestEventTableDataBKP] = useState([]);
  const [tableType, setTableType] = useState('all');
  const [employees, setEmployees] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found');
  const [clearanceUrl, setclearanceUrl] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchSSN, setSearchSSN] = useState('');
  const [searchDL, setSearchDL] = useState('');
  const [searchEIN, setSearchEIN] = useState('');
  const [searchDOB, setSearchDOB] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [searchDateRange, setSearchDateRange] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [searchClinic, setSearchClinic] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchReason, setSearchReason] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchJobTitle, setSearchJobTitle] = useState('');
  const [searchJobPosition, setSearchJobPosition] = useState('');
  const [searchDotMode, setSearchDotMode] = useState('');
  const [loadMore, setLoadMore] = useState(1);
  const [tableData, setTableData] = useState([]);
  // const [totalRecords, setTotalRecords] = useState(0);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  //const [testEventReports, setTestEventsReports] = useState([]);
  const [change, setChange] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [docPermission, setDocPermission] = useState(false);
  // const [totalCount, setTotalCount] = useState(0);
  // const [scheduledCount, setScheduledCount] = useState(0);
  // const [completedCount, setCompletedCount] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  // const [attachments, setAttachments] = useState([]);
  const [totalEmpWithEvents, setTotalEmpWithEvents] = useState(0);
  const [totalScheduledEvents, setTotalScheduledEvents] = useState(0);
  const [totalCompletedEvents, setTotalCompletedEvents] = useState(0);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [view, setView] = useState([]);
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState(false);
  const [isExcelPreparing, setIsExcelPreparing] = useState(false);
  const [excelProgress, setExcelProgress] = useState(0);
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [wijmoTableLoader, setWijmoTableLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation();
  const data = location.state;
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();
  const [latestFilterRecord, setLatestFilterRecord] = useState(data != null ? true : false);
  let tt = new wijmo.Tooltip();

  const getDocPermission = () => {
    axios
      .post(setting.serverUrl + '/api/documentPermission', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          docId: config.menuPermission.TestDetailDocs
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setDocPermission(true);
        }
      });
  };

  function download(e, attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: 'https://wss-files.s3.amazonaws.com/' + attachment
        }
      })
      .then((response) => {
        // setChange(!change)
        window.open(response.data);
      });
  }

  function downloadAttachmentMultiple(attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        console.log('response in mutiple attach', response);
        //setChange(!change);
        window.open(response && response.data);
      });
  }
  const downloadClearance = (e, event_id, emp_selected) => {
    axios
      .post(setting.serverUrl + '/api/downloadClearanceForm', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          event_id: event_id,
          employee_id: emp_selected[0],
          company: localStorage.getItem('customerId')
        }
      })
      .then((response) => {
        // setChangeCF(!changeCF);
        if (response.data.rows.length == 0) {
          setclearanceUrl('');
          alert('No Record Found');
        } else {
          setclearanceUrl(response.data.rows[0].url);
          window.open(response.data.rows[0].url);
        }

        // window.open(response.data.rows[0].url);
      });
  };
  // const getTestEvents = (checkLatestFilter) => {
  //   setIsLoading(true);
  //   // setWijmoTableLoader(true);
  //   axios
  //     .post(setting.serverUrl + '/api/dataTestEvents&Detail', {
  //       params: {
  //         user: localStorage.getItem('myData'),
  //         pass: localStorage.getItem('myPass'),
  //         company: localStorage.getItem('customerId'),
  //         loadMore: loadMore
  //       }
  //     })
  //     .then((response) => {
  //       setIsLoading(false);
  //       if (response.status === 202) {
  //         setNoRecordFound(true);
  //       } else if (response.status === 200) {
  //         console.log('resposne.data new api===========', response.data);

  //         setTestEvents(response.data.testEvent.length > 0 ? response.data.testEvent[0] : []);
  //         setShowHideAdvFilter(
  //           response.data.testEvent.length > 0 ? response.data.testEvent[0] : []
  //         );
  //         let testDetailRes =
  //           response && response.data && response.data.testDetail.length > 0
  //             ? response.data.testDetail[0]
  //             : [];
  //         let attachmentRes =
  //           response &&
  //           response.data &&
  //           response.data.attachments &&
  //           response.data.attachments.length > 0 &&
  //           response.data.attachments[0].rows;

  //         let result =
  //           testDetailRes &&
  //           testDetailRes.length > 0 &&
  //           testDetailRes.map((test) => {
  //             let attachment = attachmentRes.filter((attach) => attach.class_id == test.id);
  //             if (attachment.length > 0) {
  //               return {
  //                 ...test,
  //                 ...attachment[0]
  //               };
  //             } else {
  //               return test;
  //             }
  //           });
  //         setTestDetails(result);
  //         setEmployees(
  //           response && response.data && response.data.employees.length > 0
  //             ? response.data.employees[0]
  //             : []
  //         );
  //         setNoRecordFound(false);
  //         setChange(true);
  //         setAttachments(attachmentRes);
  //         setHasPermission(true);
  //         setLoadMoreLoader(false);
  //         setWijmoTableLoader(false);
  //         //setErrMsg('Module not activated.');
  //       } else if (response.status === 201) {
  //         setNoRecordFound(true);
  //         setHasPermission(false);
  //         setErrMsg('Access permission not allowed.');
  //         setNoRecordMessage('Access Permission not allowed.');
  //         setWijmoTableLoader(false);
  //         // notification.error({
  //         //   message: 'Failed to retrieve data at the moment. Please try again in a bit.'
  //         // });
  //       } else {
  //         notification.error({
  //           message: 'Failed to retrieve data at the moment. Please try again in a bit.'
  //         });
  //       }
  //     });
  // };

  const getTestEventsData = () => {
    setIsLoading(true);
    // if (!forceReset && !advanceFilter) {
    //   setIsLoadMoreLoading(true);
    // }
    // i = 1;
    // const customerData = JSON.parse(localStorage.getItem('customerData'));

    axios
      .post(setting.serverUrl + '/api/dataTestEvents&Detail', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          // loadMore: currentPageNumber,
          // fullName: forceReset ? '' : fullName,
          // ssn: forceReset ? '' : ssn,
          // locationSearch: forceReset ? '' : locationSearch,
          // emailSearch: forceReset ? '' : emailSearch,
          // genderSearch: forceReset ? '' : genderSearch,
          // programModeSearch: forceReset ? '' : programModeSearch,
          // statusSearch: forceReset ? '' : statusSearch,
          // searchDob: forceReset ? '' : searchDob,
          // ein: forceReset ? '' : ein,
          // dl: forceReset ? '' : dl,
          // dotMode: forceReset ? '' : dotMode,
          // searchAddCompany: forceReset ? '' : searchAddCompany,
          // searchHireRehireDate: forceReset ? '' : searchHireRehireDate,
          // searchJobTitle: forceReset ? '' : searchJobTitle,
          // searchNewDept: forceReset ? '' : searchNewDept,
          // searchNewSupervisor: forceReset ? '' : searchNewSupervisor,
          // searchStatusMarital: forceReset ? '' : searchStatusMarital,
          // searchTerminationDate: forceReset ? '' : searchTerminationDate,
          // newNationalitySearch: forceReset ? '' : newNationalitySearch,
          // newPassportSearch: forceReset ? '' : newPassportSearch,
          // //companyId: companyId,
          //custIdArr: customerData.employee_multi_select,
          company: JSON.parse(localStorage.getItem('customerData')).id
          //limit: pageLimit
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          console.log(response.data, 'response');
          response.data.sort((a, b) => {
            const dateA = new Date(a.scheduled_date);
            const dateB = new Date(b.scheduled_date);
            return dateB - dateA;
          });
          response.data.sort((a, b) => {
            const dateA = new Date(a.completed_on);
            const dateB = new Date(b.completed_on);
            return dateB - dateA;
          });

          // Function to format each item in the response
          const formatResponseItem = (item) => {
            return {
                eId: item.id,
                employee_id: item.emp_data[0].employee_id,
                empName: `${item.emp_data[0].first_name} ${item.emp_data[0].last_name}`,
                eventNote: item.event_notes || '',
                newDOB: moment(item.emp_data[0].dob).format('MM/DD/YYYY'),
                newDateStarted: item.scheduled_date ? item.scheduled_date : '',
                newDateStartedforExcel: item.scheduled_date ? moment(item.scheduled_date).format('MM/DD/YYYY') : '',
                newDateCompleted: item.completed_on ? item.completed_on : '',
                newDateCompletedforExcel: item.completed_on ? moment(item.completed_on).format('MM/DD/YYYY') : '',
                newReason: item.reasons || '',
                newStatus: item.event_status || '',
                newLocation:
                  item.emp_data[0].name &&
                  !item.emp_data[0].name.includes('|') &&
                  !item.emp_data[0].name.includes(',')
                    ? item.emp_data[0].name
                    : item.emp_data[0].name?.split('|')[1] || item.emp_data[0].name?.split(',')[1],
                newEin: item.emp_data[0].ein || '',
                newDL: item.emp_data[0].DL || '',
                newSsn: `***-**-${item.emp_data[0].emp_ssn?.substr(-4)}`,
                jobTitle: item.emp_data[0].Job_Title || '',
                departments: item.emp_data[0].department || '',
                program: item.emp_data[0].program || '',
                mode: item.emp_data[0].mode || '',
                packageName: item.test_data[0].name || '',
                testDetail: item.test_data.map((test) => ({
                item_type: test.item_type || '',
                test_name: test.test_name || '',
                reasons: test.reasons || '',
                status: test.testing_status || '',
                testing_status: test.testing_status || '',
                mroFileName: test.mroFileName || '',
                donorFileName: test.donorFileName || '',
                tenpanelreport: test.tenpanelreport || '',
                twelvepanelreport: test.twelvepanelreport || '',
                url: test.url || '',
                breathalcohol: test.breathalcohol || '',
                completedOn: test.completed_on
                  ? moment(test.completed_on).format('MM/DD/YYYY')
                  : '',
                final_result: test.final_result || '',
                eventTdNotes: test.event_note || '',
                event_id: test.event_id || '',
                emp_selected: test.emp_selected || ''
              }))
            };
          };
          const ress =
            response.data &&
            response.data.length > 0 &&
            response.data.map((item) => formatResponseItem(item));

          // ress.sort((a, b) => {
          //   const dateA = moment(a.newDateStarted, 'MM/DD/YYYY').toDate();
          //   const dateB = moment(b.newDateStarted, 'MM/DD/YYYY').toDate();
          //   // console.log("Date A:", dateA, "Date B:", dateB); // Debugging statement
          //   if (dateA > dateB) {
          //     return -1;
          //   }

          //   if (dateA < dateB) {
          //     return 1;
          //   }

          //   return 0;
          // });

          const uniqueEmployeeIds = new Set();
          let completedEventCount = 0;
          let scheduledEventCount = 0;
          ress.forEach((item) => {
            uniqueEmployeeIds.add(item.employee_id);
            if (item.newStatus === 'Completed') {
              completedEventCount++;
            } else if (item.newStatus === 'Scheduled') {
              scheduledEventCount++;
            }
          });
          const uniqueEmployeeCount = uniqueEmployeeIds.size;
          setTotalEmpWithEvents(uniqueEmployeeCount);
          setTotalScheduledEvents(scheduledEventCount);
          setTotalCompletedEvents(completedEventCount);
          setTestEventTableData(ress);
          //setWijmoFlexGrid(ress, 10);
          if (ress.length > 0) {
            const collectionView1 = new CollectionView(ress, {
              pageSize: ress.length
            });
            setViewData(collectionView1);
            const collectionView2 = new CollectionView(ress, {
              pageSize: pageSize,
              collectionChanged: (s) => {
                filterRefCounter.current = s.totalItemCount;
                var filterCounter = document.getElementById('filterCounter');
                if (filterCounter) {
                  filterCounter.textContent = s.totalItemCount;
                }
              }
            });
            if (tableType === 'all') {
              collectionView2.filters.push((item) => {
                return item;
              });
            } else if (tableType === 'Scheduled') {
              collectionView2.filters.push((item) => {
                return item.newStatus === 'Scheduled';
              });
            } else if (tableType === 'Completed') {
              collectionView2.filters.push((item) => {
                return item.newStatus === 'Completed';
              });
            }
            setView(collectionView2);
          }

          setIsLoadMoreLoading(false);
          // setTestEventTableData(response.data)
          // setEmployeeData(response.data);
          //setLoadMore(currentPageNumber);
          setLoadMoreLoader(false);

          // setTotalRecords(response.data.length);
          setHasPermission(true);
          setErrMsg('Module not activated.');
          setTableData(ress);
          setNoRecordFound(false);
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
          // notification.error({
          // message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          // });
        } else if (response.status === 202) {
          setNoRecordFound(false);
          //  setWijmoFlexGrid([], 10);
          setHasPermission(true);
          setNoRecordMessage('No Record found');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setNoRecordFound(true);
        }
      });
  };

  // console.log(tableData.map((newDateStarted)=>newDateStarted.newDateCompleted),'tableData')

  // function getFilteredTestEvents() {
  //   axios
  //     .post(setting.serverUrl + '/api/dataTestEvents&Detail', {
  //       params: {
  //         user: localStorage.getItem('myData'),
  //         pass: localStorage.getItem('myPass'),
  //         employeeName: searchName,
  //         employeeSSN: searchSSN,
  //         employeeEIN: searchEIN,
  //         employeeDL: searchDL,
  //         employeeDOB: searchDOB,
  //         employeeJobTitle: searchJobTitle,
  //         employeeJobPosition: searchJobPosition,
  //         employeeDotMode: searchDotMode,
  //         eventDateRange: searchDateRange,
  //         company: localStorage.getItem('customerId'),
  //         location: searchLocation,
  //         //clinic: searchClinic,
  //         service: searchService,
  //         reason: searchReason,
  //         status: searchStatus,
  //         loadMore: loadMore
  //       }
  //     })
  //     .then((response) => {
  //       if (response.status === 202) {
  //         setNoRecordMessage('No Record found');
  //         setNoRecordFound(true);
  //         setIsLoading(false);
  //       } else if (
  //         response.status === 200 &&
  //         response.data.attachments.length == 0 &&
  //         response.data.testEvent.length == 0 &&
  //         response.data.testDetail.length == 0 &&
  //         response.data.employees.length == 0
  //       ) {
  //         setNoRecordMessage('No Record found');
  //         setNoRecordFound(true);
  //         setIsLoading(false);
  //       } else if (response.status === 200) {
  //         setTestEvents(response.data.testEvent.length > 0 ? response.data.testEvent[0] : []);
  //         setTestDetails(response.data.testDetail.length > 0 ? response.data.testDetail[0] : []);
  //         setEmployees(response.data.employees.length > 0 ? response.data.employees[0] : []);

  //         setNoRecordFound(false);
  //         setHasPermission(true);
  //         setLoadMoreLoader(false);
  //         setWijmoTableLoader(false);
  //         //setErrMsg('Module not activated.');
  //       } else if (response.status === 201) {
  //         setIsLoading(false);
  //         setNoRecordFound(true);
  //         setNoRecordMessage('Access Permission not allowed');
  //         setErrMsg('Access permission not allowed.');
  //         notification.error({
  //           message: 'Failed to retrieve data at the moment. Please try again in a bit.'
  //         });
  //         //setIsLoading(false);
  //         setWijmoTableLoader(false);
  //       } else {
  //         notification.error({
  //           message: 'Failed to retrieve data at the moment. Please try again in a bit.'
  //         });
  //       }
  //     });
  // }

  // function getFilteredTestEventByEmployeeName() {
  //   var option = {
  //     user: localStorage.getItem('myData'),
  //     pass: localStorage.getItem('myPass'),
  //     employeeName: searchName,
  //     // employeeSSN: searchSSN,
  //     employeeDOB: searchDOB,
  //     eventDateRange: searchDateRange,
  //     location: searchLocation,
  //     service: searchService,
  //     reason: searchReason,
  //     status: searchStatus,
  //     company: localStorage.getItem('customerId')
  //   };

  //   if (typecheckValue && typecheckValue.length > 0) {
  //     typecheckValue.map((val) => {
  //       if (val === 'SSN') {
  //         option.employeeSSN = searchSSN;
  //       } else if (val === 'EIN') {
  //         option.employeeEIN = searchEIN;
  //       } else if (val === 'Driving License') {
  //         option.employeeDL = searchDL;
  //       } else {
  //       }
  //     });
  //   }

  //   axios
  //     .post(setting.serverUrl + '/api/searchEventByEmployee', {
  //       params: option
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setTestEvents(response.data.testEvent.length > 0 ? response.data.testEvent[0] : []);
  //         setTestDetails(response.data.testDetail.length > 0 ? response.data.testDetail[0] : []);
  //         //setEmployees(response.data.employees.length > 0 ? response.data.employees[0] :[]);
  //         setEmployees(response.data.employees);
  //         setLoadMoreLoader(false);
  //         setNoRecordFound(false);
  //         setIsLoading(false);
  //         setWijmoTableLoader(false);
  //       } else {
  //         setNoRecordFound(true);
  //         notification.error({
  //           message: 'Failed to retrieve data at the moment. Please try again in a bit.'
  //         });
  //       }
  //     });
  // }
  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };
  const filterTable = (type) => {
    setTableType(type);
  };

  // const createTable = () => {
  //   let tableData = [];
  //   if (employees.length == 0 && change) {
  //     console.log('NO RECORD FOUND: ', employees.length);
  //     setNoRecordFound(true);
  //   } else {
  //     let i = 1;
  //     testEvents.map((event) => {
  //       var testDetail = testDetails.filter((data) => data.event_id === event.id);

  //       var selectedEmp = [];

  //       testDetail.map((td) => {
  //         if (!selectedEmp.includes(td.emp_selected[0])) {
  //           selectedEmp.push(td.emp_selected[0]);
  //         }
  //       });

  //       selectedEmp.map((e) => {
  //         var eventData = {
  //           id: event.id,
  //           rowid: i,
  //           company: event.company,
  //           location: event.child_ids,
  //           dateStarted: event.scheduled_date,
  //           type_of_events: event.type_of_events,
  //           completedEventDate: event.event_completed_date,
  //           dateCompleted: event.completed_on,
  //           urgency_level: event.urgency_level,
  //           reason: event.reasons,
  //           packageName: event.service_multi_select,
  //           event_status: event.event_status,
  //           mode_dot: event.mode_dot,
  //           observed_Collection: event.observed_Collection,
  //           auth_form_pdf: event.auth_form_pdf,
  //           event_notes: event.event_notes,
  //           testDetail: [],
  //           employee: []
  //         };

  //         let empData = employees.filter((emp) => emp.id === e)[0];
  //         let empTestDetails = testDetail.filter((td) => td.emp_selected[0] === e);

  //         if (empData) {
  //           eventData.testDetail = empTestDetails;
  //           eventData.employee = empData;
  //           tableData.push(eventData);
  //           setNoRecordFound(false);
  //           i++;
  //         }
  //       });
  //     });
  //   }

  //   let filteredScheduled = tableData.filter((k) => {
  //     return k.event_status == 'Scheduled';
  //   });
  //   let filteredCompleted = tableData.filter((k) => {
  //     return k.event_status == 'Completed';
  //   });
  //   tableData.map((x) => (x.name = x.employee.first_name + ' ' + x.employee.last_name));

  //   // var ress = tableData.map((val) => {
  //   //   console.log('val in events===============', val);
  //   //   return {
  //   //     ...val,
  //   //     eventNote: val.event_notes != null && val.event_notes != false ? val.event_notes : '',
  //   //     newDOB: moment(val.employee.dob).format('MM/DD/YYYY'),
  //   //     newDateStarted: moment(val.dateStarted).format('MM/DD/YYYY'),
  //   //     newDateCompleted:
  //   //       val.completedEventDate !== false
  //   //         ? moment(val.completedEventDate).format('MM/DD/YYYY')
  //   //         : '',
  //   //     newReason: val.reason != false ? val.reason : '',
  //   //     newStatus: val.event_status != false ? val.event_status : '',
  //   //     //newLocation: val.location[1] != '' ? val.location[1].split(',')[1] : '',
  //   //     newLocation:
  //   //       val.location[1] != ''
  //   //         ? val.location[1].split(',')[1]
  //   //           ? val.location[1].split(',')[1].includes('|')
  //   //             ? val.location[1].split(',')[1].trim().split('|')[1].trim()
  //   //             : val.location[1].split(',')[1]
  //   //           : val.location[1].split(',')[0].trim()
  //   //         : '',
  //   //     newEin: val.employee.emp_ein != false ? val.employee.emp_ein : '',
  //   //     newSsn: val.employee.emp_ssn != false ? '***-**-' + val.employee.emp_ssn?.substr(-4) : '',
  //   //     jobTitle: val.employee.Job_Title != false ? val.employee.Job_Title : '',
  //   //     departments: val.employee.department != false ? val.employee.department : '',
  //   //     program: val.employee.program != false ? val.employee.program : '',
  //   //     mode: val.employee.mode != false ? val.employee.mode : '',
  //   //     testDetail: val.testDetail.map((val) => {
  //   //       return {
  //   //         ...val,
  //   //         completedOn:
  //   //           val.completed_on !== false ? moment(val.completed_on).format('MM/DD/YYYY') : '',
  //   //         final_result: val.final_result !== false ? val.final_result : '',
  //   //         eventTdNotes: val.event_note != null ? val.event_note : ''
  //   //       };
  //   //     })
  //   //   };
  //   // });

  //   setTestEventTableData(ress);
  //   setTestEventTableDataBKP(ress);

  //   const collectionView1 = new CollectionView(ress, {
  //     pageSize: ress.length
  //   });
  //   setViewData(collectionView1);
  //   const collectionView2 = new CollectionView(ress, {
  //     pageSize: pageSize,
  //     collectionChanged: (s) => {
  //       filterRefCounter.current = s.totalItemCount;
  //       var filterCounter = document.getElementById('filterCounter');
  //       if (filterCounter) {
  //         filterCounter.textContent = s.totalItemCount;
  //       }
  //     }
  //   });
  //   if (tableType === 'all') {
  //     collectionView2.filters.push((item) => {
  //       return item;
  //     });
  //   } else if (tableType === 'scheduled') {
  //     collectionView2.filters.push((item) => {
  //       return item.event_status === 'Scheduled';
  //     });
  //   } else if (tableType === 'completed') {
  //     collectionView2.filters.push((item) => {
  //       return item.event_status === 'Completed';
  //     });
  //   }

  //   setView(collectionView2);
  //   setTotalRecords(ress.length);
  //   setTotalCount(tableData.length);
  //   console.log('table table table', tableData);
  //   setCompletedCount(filteredCompleted.length);
  //   setScheduledCount(filteredScheduled.length);
  //   //setTestEventTableDataBKP(tableData);
  // };

  useEffect(() => {
    getTestEventsData();
    //getTestEvents();
    getDocPermission();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, [pageSize, tableType]);
  // useEffect(() => {
  //   createTable();
  // }, [employees, testEvents, testDetails, pageSize, tableType]);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    //console.log('the searches', (theSearchs.grid = theGrids));
  }

  // const handleReset = () => {
  //   setLatestFilterRecord(false);
  //   setWijmoTableLoader(true);
  //   setSearchName('');
  //   setSearchSSN('');
  //   setSearchDL('');
  //   setSearchEIN('');
  //   setSearchClinic('');
  //   setSearchCompany('');
  //   setSearchDOB('');
  //   setSearchDateRange('');
  //   setSearchLocation('');
  //   setSearchReason('');
  //   setSearchStatus('');
  //   setSearchService('');
  //   setSearchJobPosition('');
  //   setSearchJobTitle('');
  //   setSearchJobPosition('');
  //   //getTestEvents();
  // };

  // const advancedFilter = (filterType, value) => {
  //   if (filterType === 'name') {
  //     setSearchName(value);
  //   } else if (filterType === 'ssn') {
  //     setSearchSSN(value);
  //   } else if (filterType === 'dl') {
  //     setSearchDL(value);
  //   } else if (filterType === 'ein') {
  //     setSearchEIN(value);
  //   } else if (filterType === 'dob') {
  //     setSearchDOB(moment(value).format('MM/DD/YYYY'));
  //   } else if (filterType === 'date') {
  //     setSearchDateRange(value);
  //   } else if (filterType === 'company') {
  //     setSearchCompany(value);
  //   } else if (filterType === 'location') {
  //     setSearchLocation(value);
  //   } else if (filterType === 'clinic') {
  //     setSearchClinic(value);
  //   } else if (filterType === 'service') {
  //     setSearchService(value);
  //   } else if (filterType === 'reason') {
  //     setSearchReason(value);
  //   } else if (filterType === 'status') {
  //     setSearchStatus(value);
  //   } else if (filterType === 'jobTitle') {
  //     setSearchJobTitle(value);
  //   } else if (filterType === 'departments') {
  //     setSearchJobPosition(value);
  //   } else if (filterType === 'mode') {
  //     setSearchDotMode(value);
  //   }
  // };

  // const showAdvanceFilterModal = () => {
  //   setIsAdvanceFilterModalOpen(true);
  // };

  // const handleAdvanceFilterCancel = () => {
  //   setIsAdvanceFilterModalOpen(false);
  // };

  // const handleAdvanceFilter = () => {
  //   if (
  //     searchName.length > 0 &&
  //     (searchSSN.length > 0 || searchDL.length > 0 || searchEIN.length > 0) &&
  //     searchDOB.length > 0 &&
  //     searchDateRange.length > 0
  //   ) {
  //     setIsLoading(true);
  //     setWijmoTableLoader(true);
  //     setIsAdvanceFilterModalOpen(false);
  //     getFilteredTestEventByEmployeeName();
  //     setLoadMoreLoader(true);
  //   } else {
  //     setWijmoTableLoader(true);
  //     setIsLoading(true);
  //     setIsAdvanceFilterModalOpen(false);
  //     setLoadMoreLoader(true);
  //     getFilteredTestEvents();
  //   }
  // };
  function downloadVaccinationAuth(e, attachment) {
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: attachment
        }
      })
      .then((response) => {
        if (response && response.status === 200) {
          setChange(!change);
          if (Array.isArray(response.data)) {
            setIsModalVisible(true);
            setImageUrls(response.data);
          } else {
            window.open(response.data);
          }
          // if (Array.isArray(response.data)) {
          //   window.open(response.data[0].url);
          // }
        }
      });
  }
  // const handleClinicHours = (data) => {
  //   console.log('data handleClinicHours::', data);
  //   let propData = {
  //     company: data.company,
  //     child_ids: data.location,
  //     urgency_level: 'Screening',
  //     reasons: data.reason,
  //     service_multi_select: data.packageName,
  //     test_name: 'TEST',
  //     scheduled_date: data.dateStarted,
  //     event_status: data.event_status
  //   };

  //   history.push({ pathname: `/vertical/editTestingDetails`, state: { data: propData } });
  // };
  // const handleClinicHoursNN = (data) => {
  //   console.log('data handleClinicHoursNN::', data);
  //   history.push({ pathname: `/vertical/testingDetails`, state: { data: data, id: data.id } });
  //   //history.push({ pathname: `/vertical/testingDetails`, state: data.id});
  // };

  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          resetState();
        },
        (err) => {
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
    } else {
      exportService.cancelExcelExport((progress) => {
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  // const showLocation = (item) => {
  //   return (
  //     <p>
  //       {item.item.location.length > 0 && item.item.company.length > 0
  //         ? item.item.location[1].toString().replace(item.item.company[1].toString() + ', ', '')
  //         : ''}
  //     </p>
  //   );
  // };

  // const showDOB = (item) => {
  //   // console.log("reuturn",item)
  //   return (
  //     <p>
  //       {item.item.employee && item.item.employee.dob != false
  //         ? moment(item.item.employee.dob).format('MM/DD/YYYY')
  //         : ''}
  //     </p>
  //   );
  // };

  const showSSN = (item) => {
    return <span>{item.item.newSsn}</span>;
  };
  const showLoc = (item) => {
    return <span>{item.item.newLocation}</span>;
  };

  const showEmpName = (item) => {
    return <span>{item.item.empName}</span>;
  };
  const showEIN = (item) => {
    return <span>{item.item.newEin}</span>;
  };

  const showDL = (item) => {
    return <span>{item.item.employee?.DL}</span>;
  };

  const showReasons = (item) => {
    return <p>{item.item.newReason !== false ? item.item.newReason : ''}</p>;
  };

  const showPackageName = (item) => {
    return <p>{item.item.packageName ? item.item.packageName : ''}</p>;
  };

  // const showJobTitle = (item) => {
  //   return <p>{item.item.jobTitle ? item.item.jobTitle : ''}</p>;
  // };

  const showDotMode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  const showScheduleDate = (item) => {
    return (
      <p>
        {item.item.newDateStarted && item.item.newDateStarted != false
          ? moment(item.item.newDateStarted).format('MM/DD/YYYY')
          : ''}
      </p>
    );
  };

  const dateCompleted = (item) => {
    return (
      <p>
        {item.item.newDateCompleted && item.item.newDateCompleted != false
          ? moment(item.item.newDateCompleted).format('MM/DD/YYYY')
          : ''}
      </p>
    );
  };

  const showAuthForm = (item) => {
    return item.item.auth_form_pdf == false ? (
      ''
    ) : (
      <button
        style={{ whiteSpace: 'nowrap' }}
        onClick={(e) => downloadVaccinationAuth(e, item.item.auth_form_pdf)}
      >
        Auth Form
      </button>
    );
  };

  const getSubItemSource = (item) => {
    //  return testEventTable[item.item.rowId - 1].testDetail;
    //return testEventTable[].testDetail;
    const resExp = testEventTable.filter((value) => {
      if (value.eId === item.item.eId) {
        console.log('particular employee===', value.testDetail);
        return value.testDetail;
      }
    });
    return resExp[0].testDetail;
  };

  const showItemType = (item) => {
    return (
      <p>
        {item.item.item_type && item.item.item_type.length > 0 ? (
          item.item.item_type === 'drug and alcohol' ? (
            <p> Drug & Alcohol </p>
          ) : item.item.item_type === 'medical surveillance' ? (
            <p>Occupational Health</p>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </p>
    );
  };

  const showTestName = (item) => {
    return <p>{item.item.test_name}</p>;
  };

  var showTestReasons = (item) => {
    return <p>{item.item.reasons ? item.item.reasons : ''}</p>;
  };

  const showResult = (item) => {
    return item.item.final_result === 'NEGATIVE' ? (
      <p style={{ color: 'green' }}>{item.item.final_result}</p>
    ) : item.item.final_result === 'POSITIVE' ? (
      <p style={{ color: 'red' }}>{item.item.final_result}</p>
    ) : item.item.final_result !== 'POSITIVE' && item.item.final_result !== 'NEGATIVE' ? (
      <p style={{ color: 'orange' }}>{item.item.final_result}</p>
    ) : (
      ''
    );
  };

  const showCompledOn = (item) => {
    return <p>{item.item.completedOn ? moment(item.item.completedOn).format('MM/DD/YYYY') : ''}</p>;
  };

  const showFilename = (item) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {docPermission ? (
          <>
            {' '}
            {item.item.mroFileName == false ? (
              ''
            ) : (
              <>
                &nbsp; &nbsp;
                {/* <button
                  style={{ width: '15%', marginBottom: '0px', marginTop: '-3px' }}
                  onClick={(e) => download(e, item.item.mroFileName)}
                >
                  Results
                </button> */}
                {/* <p style={{ color: 'blue' }}>
                  Results */}
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download(e, item.item.mroFileName)}
                />
                {/* </p> */}
              </>
            )}
            {/* {item.item.ccfFileName == false ? (
              ''
            ) : (
              <>
                {' '}
                &nbsp; &nbsp;
                <button onClick={(e) => download(e, item.item.ccfFileName)}>CCF</button>
              </>
            )}
            &nbsp; &nbsp; */}
            {item.item.donorFileName == false ? (
              ''
            ) : (
              <>
                &nbsp; &nbsp;
                {/* <button
                  style={{ width: '20%', marginBottom: '0px', marginTop: '-3px' }}
                  onClick={(e) => download(e, item.item.donorFileName)}
                >
                  Donor Pass
                </button> */}
                {/* <p style={{ color: 'blue' }}>
                  Donor Pass */}
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download(e, item.item.donorFileName)}
                />
                {/* </p> */}
              </>
            )}
            {item.item.tenpanelreport == false ? (
              ''
            ) : (
              <>
                &nbsp; &nbsp;
                {/* <button
                  style={{ width: '15%', marginBottom: '0px', marginTop: '-3px' }}
                  onClick={(e) => download(e, item.item.tenpanelreport)}
                >
                  Results
                </button> */}
                {/* <p style={{ color: 'blue' }}>
                  Results */}
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download(e, item.item.tenpanelreport)}
                />
                {/* </p> */}
              </>
            )}
            {item.item.twelvepanelreport == false ? (
              ''
            ) : (
              <>
                &nbsp; &nbsp;
                {/* <button
                  style={{ width: '15%', marginBottom: '0px', marginTop: '-3px' }}
                  onClick={(e) => download(e, item.item.twelvepanelreport)}
                >
                  Results
                </button> */}
                {/* <p style={{ color: 'blue' }}>
                  Results */}
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download(e, item.item.twelvepanelreport)}
                />
                {/* </p> */}
              </>
            )}
            {item.item.breathalcohol == false ? (
              ''
            ) : (
              <>
                &nbsp; &nbsp;
                {/* <button
                  style={{ width: '15%', marginBottom: '0px', marginTop: '-3px' }}
                  onClick={(e) => download(e, item.item.breathalcohol)}
                >
                  Results
                </button> */}
                {/* <p style={{ color: 'blue' }}>
                  Results */}
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => download(e, item.item.breathalcohol)}
                />
                {/* </p> */}
              </>
            )}
            {item.item.event_status === 'Completed' ? (
              <>
                &nbsp; &nbsp;
                {/* <button
                  style={{ width: '20%', marginBottom: '0px', marginTop: '-3px' }}
                  onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}
                >
                  Clearance Form
                </button> */}
                {/* <p style={{ color: 'blue' }}>
                  Clearance Form */}
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => downloadClearance(e, item.item.event_id, item.item.emp_selected)}
                />
                {/* </p> */}
              </>
            ) : (
              ''
            )}
            {item.item.url != '' ? (
              <>
                &nbsp; &nbsp;
                {/* <button
                  style={{ width: '22%', marginBottom: '0px', marginTop: '-3px' }}
                  onClick={(e) => downloadAttachmentMultiple(item.item.url)}
                >
                  Attachments
                </button> */}
                {/* <p style={{ color: 'blue' }}>
                  Attachments */}
                <FolderOpenTwoTone
                  twoToneColor='#cc7a00'
                  style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={(e) => downloadAttachmentMultiple(item.item.url)}
                />
                {/* </p> */}
              </>
            ) : (
              ''
            )}
            {/* {item.item && item.item.attachmentUrls && item.item.attachmentUrls.length > 0
              ? item.item.attachmentUrls.map((url) => {
                  return (
                    <>
                      &nbsp; &nbsp;
                      <button
                        style={{ width: '50%', marginBottom: '5px' }}
                        //style={{ margin: 3 }}
                        onClick={() => downloadAttachmentMultiple(url.url)}
                      >
                        Attachments
                      </button>
                    </>
                  );
                })
              : ''} */}
            {/* {record.auth_form_pdf == false ? "" :
      (<button  onClick={(e)=>downloadVaccinationAuth( e, record.auth_form_pdf)}>Auth Form</button>)} */}
          </>
        ) : (
          (item.item.mroFileName ||
            item.item.url ||
            // item.item.attachmentUrls ||
            item.item.event_status === 'Completed' ||
            item.item.breathalcohol ||
            item.item.twelvepanelreport ||
            item.item.tenpanelreport ||
            item.item.donorFileName ||
            // item.item.ccfFileName ||
            item.item.mroFileName) && (
            <CheckSquareTwoTone style={{ color: 'green', fontSize: 15, marginBottom: '5px' }} />
          )
        )}
      </div>
    );
  };
  const showMessage = (item) => {
    return (
      <p>
        {item.item.eventNote != null && item.item.eventNote.length > 40
          ? item.item.eventNote.substring(0, 37) + '...'
          : item.item.eventNote}
      </p>
    );
  };
  const showTdMessage = (item) => {
    return (
      <p>
        {item.item.eventTdNotes != null && item.item.eventTdNotes.length > 40
          ? item.item.eventTdNotes.substring(0, 37) + '...'
          : item.item.eventTdNotes}
      </p>
    );
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    if (e.panel == sender.cells) {
      // Specify the cell you want to add a tooltip to
      if (e.col === 9) {
        if (sender.rows[e.row]._data.eventNote && sender.rows[e.row]._data.eventNote.length > 40) {
          tt.setTooltip(e.cell, sender.rows[e.row]._data.eventNote);
        }
      }
    }
  };
  const formatTdItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    if (e.panel == sender.cells) {
      // Specify the cell you want to add a tooltip to
      if (e.col === 6) {
        if (
          sender.rows[e.row]._data.eventTdNotes &&
          sender.rows[e.row]._data.eventTdNotes.length > 40
        ) {
          tt.setTooltip(e.cell, sender.rows[e.row]._data.eventTdNotes);
        }
      }
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 37; // You can adjust the height as needed
    }
  };

  return hasPermission ? (
    <div>
      <Row gutter={[32, 32]}>
        <Col lg={{ span: 16 }} md={{ span: 16 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Scheduled Events</h3>
        </Col>
        {/* <Col span={1}> */}
        {/* <Tooltip placement='top' title='Create Test Event'>
            <PlusCircleTwoTone
              twoToneColor='#247'
              style={{ fontSize: 25, marginTop: 40, cursor: 'pointer' }}
              onClick={() => history.push({ pathname: `/vertical/addTestEvent` })}
            />
          </Tooltip> */}
        {/* </Col> */}
      </Row>

      <br />

      {!noRecordFound ? (
        testEventTable.length > 0 ? (
          <div>
            <div className='row'>
              <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                <Card
                  style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                  className='animated with-shadow'
                  onClick={() => filterTable('all')}
                >
                  <div className='row'>
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <div
                        className='count'
                        style={{
                          fontSize: 40,
                          color: '#EF6827',
                          lineHeight: 1.4,
                          fontWeight: 'bold'
                        }}
                      >
                        {/* {totalCount ? totalCount : <span>0</span>} */}
                        {totalEmpWithEvents}
                      </div>
                      <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                        Total <br /> Employees With Events
                      </h6>
                    </div>
                  </div>
                </Card>
              </div>

              <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                <Card
                  style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                  className='animated with-shadow'
                  onClick={() => filterTable('Scheduled')}
                >
                  <div className='row'>
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <div
                        className='count'
                        style={{
                          fontSize: 40,
                          color: '#EF6827',
                          lineHeight: 1.4,
                          fontWeight: 'bold'
                        }}
                      >
                        {/* {scheduledCount ? scheduledCount : <span>0</span>} */}
                        {totalScheduledEvents}
                      </div>
                      <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                        Employees with Scheduled Status
                      </h6>
                    </div>
                  </div>
                </Card>
              </div>

              <div className='col-12 col-md-6 col-xl-2' style={{ cursor: 'pointer' }}>
                <Card
                  style={{ background: 'rgba(251, 251, 251)', height: 150 }}
                  className='animated with-shadow'
                  onClick={() => filterTable('Completed')}
                >
                  <div className='row'>
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <div
                        className='count'
                        style={{
                          fontSize: 40,
                          color: '#EF6827',
                          lineHeight: 1.4,
                          fontWeight: 'bold'
                        }}
                      >
                        {/* {completedCount ? completedCount : <span>0</span>} */}
                        {totalCompletedEvents}
                      </div>
                      <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                        Employees with Completed Status
                      </h6>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <br />

            {wijmoTableLoader && (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ margin: '30px 0px 30px 0px' }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            )}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 170 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                    getPopupContainer={() => fixedContainerRef.current}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size='small'
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn binding='empName' header='Employee Name' minWidth={180} width='*' />
              {typecheckValue &&
                typecheckValue.length > 0 &&
                typecheckValue.map((value) => {
                  return (
                    <FlexGridColumn
                      binding={
                        value === 'SSN'
                          ? 'newSsn'
                          : value === 'EIN'
                          ? 'newEin'
                          : value === 'Driver License'
                          ? 'employee.DL'
                          : ''
                      }
                      header={
                        value === 'SSN'
                          ? 'SSN'
                          : value === 'EIN'
                          ? 'EIN'
                          : value === 'Driver License'
                          ? 'Driver License'
                          : ''
                      }
                      width='*'
                      minWidth={120}
                    >
                      <FlexGridCellTemplate
                        cellType='Cell'
                        template={
                          value === 'SSN'
                            ? showSSN
                            : value === 'EIN'
                            ? showEIN
                            : value === 'Driver License'
                            ? showDL
                            : ''
                        }
                      />
                    </FlexGridColumn>
                  );
                })}
              {/* <FlexGridColumn binding='newDOB' header='DOB' minWidth={120} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showDOB} />
              </FlexGridColumn> */}

              <FlexGridColumn binding='newLocation' header='Location' minWidth={250} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showLoc} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='newDateStartedforExcel'
                header='Scheduled Date'
                minWidth={160}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showScheduleDate} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='newDateCompletedforExcel'
                header='Date Completed'
                // width='10*'
                minWidth={150}
                width='*'
              ></FlexGridColumn>
              <FlexGridColumn
                binding='newStatus'
                header='Status'
                // wordWrap={true}
                // width='11*'
                minWidth={120}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' />
              </FlexGridColumn>

              <FlexGridColumn
                binding='packageName'
                header='Package Name'
                // width='10*'
                minWidth={200}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showPackageName} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='newReason'
                header='Reason'
                // width='12*'
                minWidth={130}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showReasons} />
              </FlexGridColumn>

              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                minWidth={160}
                width='*'
              >
              <FlexGridCellTemplate cellType='Cell' template={showJobTitle} />
              </FlexGridColumn> */}

              {/* <FlexGridColumn
                binding='departments'
                header='Job Position'
                // width='10*'
                minWidth={160}
                width='*'
              /> */}

              <FlexGridColumn
                binding='program'
                header='DOT Mode'
                // width='10*'
                minWidth={160}
                width='*'
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn>

              <FlexGridColumn binding='eventNote' header='Notes' minWidth={200} width='*' />

              <FlexGridDetail
                template={(ctx) => (
                  <FlexGrid
                    autoGenerateColumns={false}
                    headersVisibility='Column'
                    alternatingRowStep={1}
                    itemsSource={getSubItemSource(ctx)}
                    itemsPerPage={10}
                    isReadOnly={true}
                  >
                    <FlexGridColumn
                      binding='item_type'
                      header='Item Type'
                      width='*'
                      minWidth={150}
                      // wordWrap={true}
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showItemType} />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding='test_name'
                      header='Test Name'
                      width='*'
                      // wordWrap={true}
                      minWidth={150}
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showTestName} />
                    </FlexGridColumn>
                    <FlexGridColumn binding='reasons' header='Reason' width='*' minWidth={150}>
                      <FlexGridCellTemplate cellType='Cell' template={showTestReasons} />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding='testing_status'
                      header='Status'
                      width='*'
                      minWidth={150}
                    />
                    <FlexGridColumn binding='final_result' header='Result' width='*' minWidth={150}>
                      <FlexGridCellTemplate cellType='Cell' template={showResult} />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding='completedOn'
                      header='Date Completed'
                      width='*'
                      minWidth={150}
                    >
                      <FlexGridCellTemplate cellType='Cell' template={showCompledOn} />
                    </FlexGridColumn>
                    <FlexGridColumn
                      binding='eventTdNotes'
                      header='Notes'
                      width='*'
                      minWidth={150}
                    />

                    <FlexGridColumn binding='FileName' header='Documents' width='*' minWidth={150}>
                      <FlexGridCellTemplate cellType='Cell' template={showFilename} />
                    </FlexGridColumn>
                    {/* <FlexGridFilter
                      filterColumns={[
                        'item_type',
                        'test_name',
                        'reasons',
                        'final_result',
                        'testing_status',
                        'completedOn',
                        'departments',
                        'jobTitle',
                        'program',
                        'mode',
                        'empName'
                      ]}
                    /> */}
                  </FlexGrid>
                )}
              />
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className='cliniclisttable'
              alternatingRowStep={0}
              // autoRowHeights={true}
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
              scrollMode='Virtual' // Add this line for smooth scrolling
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn binding='empName' header='Employee Name' width={250}>
                <FlexGridCellTemplate cellType='Cell' template={showEmpName} />
              </FlexGridColumn>

              {/* <FlexGridColumn binding='newDOB' header='DOB' width={100}>
                <FlexGridCellTemplate cellType='Cell' template={showDOB} />
              </FlexGridColumn> */}

              {typecheckValue &&
                typecheckValue.length > 0 &&
                typecheckValue.map((value) => {
                  return (
                    <FlexGridColumn
                      binding={
                        value === 'SSN'
                          ? 'newSsn'
                          : value === 'EIN'
                          ? 'newEin'
                          : value === 'Driver License'
                          ? 'employee.DL'
                          : ''
                      }
                      header={
                        value === 'SSN'
                          ? 'SSN'
                          : value === 'EIN'
                          ? 'EIN'
                          : value === 'Driver License'
                          ? 'Driver License'
                          : ''
                      }
                      width={120}
                    >
                      <FlexGridCellTemplate
                        cellType='Cell'
                        template={
                          value === 'SSN'
                            ? showSSN
                            : value === 'EIN'
                            ? showEIN
                            : value === 'Driver License'
                            ? showDL
                            : ''
                        }
                      />
                    </FlexGridColumn>
                  );
                })}

              <FlexGridColumn binding='newLocation' header='Location' width={255}>
                <FlexGridCellTemplate cellType='Cell' template={showLoc} />
              </FlexGridColumn>
              <FlexGridColumn binding='newDateStarted' header='Scheduled Date' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showScheduleDate} />
              </FlexGridColumn>
              <FlexGridColumn binding='newDateCompleted' header='Date Completed' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={dateCompleted} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='newStatus'
                header='Status'
                // wordWrap={true}
                // width='11*'
                width={135}
              >
                <FlexGridCellTemplate cellType='Cell' />
              </FlexGridColumn>
              <FlexGridColumn
                binding='packageName'
                header='Package Name'
                // width='10*'
                width={300}
                // wordWrap={true}
              >
                <FlexGridCellTemplate cellType='Cell' template={showPackageName} />
              </FlexGridColumn>

              <FlexGridColumn
                binding='newReason'
                header='Reason'
                // width='12*'
                width={250}
                // wordWrap={true}
              >
                <FlexGridCellTemplate cellType='Cell' template={showReasons} />
              </FlexGridColumn>
              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                minWidth={180}
                width='*'
                wordWrap={true}
              >
                <FlexGridCellTemplate cellType='Cell' template={showJobTitle} />
              </FlexGridColumn> */}

              <FlexGridColumn
                binding='program'
                header='DOT Mode'
                // width='10*'
                width={115}
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn>

              <FlexGridColumn binding='eventNote' header='Notes' width={250}>
                <FlexGridCellTemplate cellType='Cell' template={showMessage} />
              </FlexGridColumn>

              {/* <FlexGridColumn binding='auth_form_pdf' header='Auth Form' minWidth={100} width='*'>
                <FlexGridCellTemplate cellType='Cell' template={showAuthForm} />
              </FlexGridColumn> */}

              <FlexGridDetail
                template={(ctx) => (
                  <div style={{ overflowX: 'auto', transition: 'width 0.5s ease' }}>
                    <FlexGrid
                      id='theGridTallRows'
                      autoGenerateColumns={false}
                      headersVisibility='Column'
                      alternatingRowStep={1}
                      //formatItem={formatItem}
                      // autoRowHeights={true}
                      // loadedRows={onloadedRows.bind(}
                      itemsSource={getSubItemSource(ctx)}
                      itemsPerPage={10}
                      isReadOnly={true}
                      formatItem={formatTdItem}
                      scrollMode='Virtual' // Add this line for smooth scrolling
                    >
                      <FlexGridColumn
                        binding='item_type'
                        header='Item Type'
                        // width='*'
                        // minWidth={150}
                        width={193}
                        // wordWrap={true}
                      >
                        <FlexGridCellTemplate cellType='Cell' template={showItemType} />
                      </FlexGridColumn>
                      <FlexGridColumn
                        binding='test_name'
                        header='Test Name'
                        // width='*'
                        width={375}
                        // wordWrap={true}
                        // minWidth={180}
                      >
                        <FlexGridCellTemplate cellType='Cell' template={showTestName} />
                      </FlexGridColumn>
                      <FlexGridColumn binding='reasons' header='Reason' width={256}>
                        <FlexGridCellTemplate cellType='Cell' template={showTestReasons} />
                      </FlexGridColumn>
                      <FlexGridColumn
                        binding='testing_status'
                        header='Status'
                        // width='*'
                        // minWidth={150}
                        width={135}
                      />
                      <FlexGridColumn binding='final_result' header='Result' width={178}>
                        <FlexGridCellTemplate cellType='Cell' template={showResult} />
                      </FlexGridColumn>
                      <FlexGridColumn
                        binding='completedOn'
                        header='Date Completed'
                        // width='*'
                        // minWidth={180}
                        width={142}
                      >
                        <FlexGridCellTemplate cellType='Cell' template={showCompledOn} />
                      </FlexGridColumn>
                      <FlexGridColumn binding='eventTdNotes' header='Notes' width={364}>
                        <FlexGridCellTemplate cellType='Cell' template={showTdMessage} />
                      </FlexGridColumn>

                      <FlexGridColumn binding='FileName' header='Documents' width={290}>
                        <FlexGridCellTemplate cellType='Cell' template={showFilename} />
                      </FlexGridColumn>
                      <FlexGridFilter
                        filterColumns={[
                          'item_type',
                          'test_name',
                          'reasons',
                          'testing_status',
                          'final_result',
                          'completedOn'
                        ]}
                      />
                    </FlexGrid>
                  </div>
                )}
                isAnimated={true} // Add this if you want to animate expansion
              />
              <FlexGridFilter
                filterColumns={[
                  'name',
                  'newLocation',
                  'newDOB',
                  'newReason',
                  'newEin',
                  'employee.emp_ein',
                  'newSsn',
                  'packageName[1]',
                  'event_status',
                  'newStatus',
                  'newDateStarted',
                  'newDateCompleted',
                  'departments',
                  'jobTitle',
                  'program',
                  'event_notes'
                ]}
              />
            </FlexGrid>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                ({Globalize.format(filterRefCounter.current, 'n0')} )
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {tableData.length}
              </p>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <>
          {isLoading ? (
            <Row>
              <Col span={10}></Col>
              <Col span={8}>
                <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                  <Spin size='large' />
                </Space>
              </Col>
            </Row>
          ) : (
            <h5 style={{ color: '#ef6727', textAlign: 'center' }}>No Records Found</h5>
          )}
        </>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className='icofont-exclamation-tringle' style={{ color: 'red', fontSize: 20 }}></i> */}
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default TestingEventsView;
