import GoogleMapReact from "google-map-react";
import { Tooltip } from "antd";
import React, { useEffect, useState, useRef } from 'react';
import {
  Spin,
  Space,
  Row,
  Input,
  Modal,
  DatePicker,
  notification,
  Col,
  Button,
  Select
} from 'antd';
import { history } from './../../redux/store';
import axios from 'axios';
import _ from 'underscore';
import setting from './../../config/setting';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import Search from 'antd/lib/input/Search';
import { EnvironmentOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import clinic_marker from '../../assets/img/clinic_marker.png'
import clinic_building from '../../assets/img/clinic_building.png'
let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

const Marker = ({ locationDetails }) => (
  <Tooltip
    color="white"
    text="black"
    title={
      <div style={{ display: "flex", padding: "5px", color: "#000" }}>
        {/* Clinic Building Section */}
        <div style={{ marginRight: "15px" }}>
          <img
            src={clinic_building}
            alt="Clinic Building"
            style={{ width: "80px", height: "40px", objectFit: 'cover', objectPosition: 'center' }}
          />
        </div>
        {/* Location Details Section */}
        <div style={{ fontSize: "14px", lineHeight: "1.5" }}>
          {locationDetails}
        </div>
      </div>
    }
  >
    <div
      style={{
        color: "blue",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      <img src={clinic_marker} alt="Clinic Marker" style={{ width: "18px" }} />
    </div>
  </Tooltip>




);

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'clinic_list.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const ClinicList = () => {
  const [clinicList, setClinicList] = useState([]);
  const [clinicResponse, setClinicResponse] = useState([]);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found!');
  const [nofound, setNofound] = useState(false);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  const [view, setView] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [viewData, setViewData] = useState(null);
  const [loadMore, setLoadMore] = useState(1);
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [clinicType, setClinicType] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [authEmail, setAuthEmail] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [zipValue, setZipValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const gridRef = useRef(null);
  const pageLimit = 100;
  const filterRefCounter = useRef();
  const [newClinic, setNewClinic] = useState('');

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    console.log(value, 'paggination');
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const defaultProps = {
    center: {
      lat: 37.0902,
      lng: -95.7129,
    },
    zoom: 4,
  };

  function getDataCount(forceReset = false) {
    axios
      .post(setting.serverUrl + '/api/getClinicsCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          clinicName: forceReset ? '' : clinicName || newClinic,
          authEmail: forceReset ? '' : authEmail,
          clinicType: forceReset ? '' : clinicType,
          cityValue: forceReset ? '' : cityValue,
          stateValue: forceReset ? '' : stateValue,
          zipValue: forceReset ? '' : zipValue
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          setTotalRecords(response.data.value);
        }
      });
  }
  function getData(currentPageNumber = 1, forceReset = false, advanceFilter = false) {
    setIsLoading(true);
    if (!forceReset && !advanceFilter) {
      setIsLoadMoreLoading(true);
    }
    i = 1;
    axios
      .post(setting.serverUrl + '/api/getClinics', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          customerId: localStorage.getItem('customerId'),
          loadMore: forceReset ? '' : currentPageNumber,
          clinicName: forceReset ? '' : clinicName || newClinic,
          authEmail: forceReset ? '' : authEmail,
          clinicType: forceReset ? '' : clinicType,
          cityValue: forceReset ? '' : cityValue,
          stateValue: forceReset ? '' : stateValue,
          zipValue: forceReset ? '' : zipValue,
          limit: pageLimit
        }
      })
      .then((response) => {
        setIsLoading(false);
        if (response && response.status == 200 && response.data.error != 'Technical Issue') {
          let responseData = response && response?.data;
          const finalResponse = responseData?.map((k) => {
            return {
              ...k,
              clinic_name: k.clinic_name != false ? k.clinic_name : '',
              phone: k.phone != false ? k.phone : '',
              emails_legacy: k.emails_legacy != false ? k.emails_legacy : '',
              Type: k.Type != false ? k.Type : '',
              city: k.city != false ? k.city : '',
              state_id:
                k.state_id && k.state_id.length > 0 && k.state_id[1] != false ? k.state_id[1] : '',
              zip: k.zip != false ? k.zip : ''
            };
          });
          const collectionView1 = new CollectionView(finalResponse, {
            pageSize: finalResponse.length
          });
          setViewData(collectionView1);
          const collectionView2 = new CollectionView(finalResponse,
            {
              pageSize: pageSize,
              collectionChanged: (s) => {
                console.log(s, 'collectionChanged');
                filterRefCounter.current = s.totalItemCount;
                var filterCounter = document.getElementById('filterCounter');
                if (filterCounter) {
                  filterCounter.textContent = s.totalItemCount;
                }
              }
            });

          setView(collectionView2);
          setIsLoadMoreLoading(false);
          setClinicList(finalResponse);
          setLoadMoreLoader(false);
          //setWijmoFlexGrid(finalResponse, 10);
          setTableData(finalResponse);
          setLoadMore(currentPageNumber);
          setClinicResponse(finalResponse);
          setHasPermission(true);
          setNofound(false);
        } else if (response.status === 201) {
          setErrMsg('Access permission not allowed.');
          setNoRecordMessage('Access permission not allowed.');
        } else if (response.data.error == 'Technical Issue') {
          setNofound(false);
          setNoRecordMessage('No Record Found!');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }
  const exportToExcel = () => {
    const exportService = new ExportService();

    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        showPicker(e);
      });
      e.cell.appendChild(span);
    }

    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    console.log('hi   ctl', ctl);
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  useEffect(() => {
    getData();
    getDataCount();
  }, [pageSize]);

  // let theGrids = theGrid.current;
  // let theSearchs = theSearch.current;
  // if (theGrids && theSearchs) {
  //   theSearchs.control.grid = theGrids.control;
  // }
  const showAdvanceFilterModal = () => {
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    if (
      (clinicName && clinicName.length > 0) ||
      (authEmail && authEmail.length > 0) ||
      (clinicType && clinicType.length > 0) ||
      (cityValue && cityValue.length > 0) ||
      (stateValue && stateValue.length > 0) ||
      (zipValue && zipValue.length > 0)
    ) {
      getData(1, false, true);
      setIsAdvanceFilterModalOpen(false);
      getDataCount();
      setLoadMoreLoader(true);
    } else {
      setTableData(clinicResponse);
      setIsAdvanceFilterModalOpen(false);
    }
  };

  const handleChange = (e) => {
    setNewClinic(e.target.value);
    // setClinicName(newClinic);
  };

  const onSearch = (value) => {
    if (newClinic.length > 0) {
      console.log('Name');
      getData(1, false, true);
      getDataCount();
      // setClinicName('')
    }
  };

  const handleReset = () => {
    setIsLoading(true);
    setLoadMoreLoader(true);
    setNewClinic('');
    setClinicName('');
    setCityValue('');
    setAuthEmail('');
    setClinicType('');
    setStateValue('');
    setZipValue('');
    getData(1, true);
    getDataCount(true);
  };
  const shouldShowLoadMore = () => {
    return loadMore * pageLimit <= totalRecords;
  };

  const handleShowClinicInfo = (clinicInfo) => {
    history.push({
      pathname: '/vertical/clinicdetails',
      state: clinicInfo
    });
  };

  // const showActions = (item) => {
  //   return (
  //     <div className='buttons-list nowrap'>
  //       <Button
  //         shape='circle'
  //         onClick={() => handleShowClinicInfo(item.item)}
  //         danger
  //         style={{
  //           background: 'none',
  //           border: 'none',
  //           boxShadow: 'none',
  //           color: 'rgb(239,103,39)',
  //           margin: '-5px 4px'
  //         }}
  //       >
  //         <span className='icofont icofont-info-circle' style={{ fontSize: 15 }} />
  //       </Button>
  //     </div>
  //   );
  // };

  const showActions = (item) => {
    return (
      // <div className='buttons-list nowrap'>
      <InfoCircleTwoTone
        onClick={() => handleShowClinicInfo(item.item)}
        style={{ marginLeft: 15, marginTop: 1, color: '#0066ff', fontSize: 15 }}
        twoToneColor='#0066ff'
      />
      // </div>
    );
  };

  const columns = [
    { header: 'Type of Clinic', field: 'Type', width: 115 },
    { header: 'City', field: 'city', width: 130 },
    { header: 'State', field: 'state_id', width: 125 },
    { header: 'Zip', field: 'zip', width: 75, minWidth: '100' }
  ];
  const showClinicName = (item) => {
    return <p>{item && item.item.clinic_name != false ? item.item.clinic_name : ''}</p>;
  };
  const showAuthEmail = (item) => {
    return <p>{item && item.item.emails_legacy != false ? item.item.emails_legacy : ''}</p>;
  };
  const showPhoneNumber = (item) => {
    return <p>{item && item.item.phone != false ? item.item.phone : ''}</p>
  }

  return hasPermission ? (
    <div style={{ paddingTop: 0, paddingLeft: 20, paddingRight: 20, marginTop: -50 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <div style={{ display: 'flex' }}>
          <h3 style={{ textAlign: 'left', color: '#1C3A6A' }}>Clinic List</h3>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            size='small'
            style={{
              backgroundColor: '#247',
              border: 'none',
              marginRight: '10px' // Adjust spacing between buttons
            }}
            onClick={showAdvanceFilterModal}
          >
            Advanced Search Filter
          </Button>
          <Modal
            width={1000}
            title='Advanced Search Filter'
            visible={isAdvanceFilterModalOpen}
            onCancel={handleAdvanceFilterCancel}
            footer={[
              <Button
                size='small'
                style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                onClick={() => handleAdvanceFilter()}
              >
                Search
              </Button>
            ]}
          >
            <Row gutter={[24, 24]}>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={clinicName}
                  placeholder='Clinic Name :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setClinicName(event.target.value)}
                />
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={authEmail}
                  placeholder='Authorization Email :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setAuthEmail(event.target.value)}
                />
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={clinicType}
                  placeholder='Type of Clinic :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setClinicType(event.target.value)}
                />
              </Col>

              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={cityValue}
                  placeholder='City :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setCityValue(event.target.value)}
                />
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={stateValue}
                  placeholder='State :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setStateValue(event.target.value)}
                />
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                <Input
                  value={zipValue}
                  placeholder='Zip :'
                  suffix={<span className='icofont icofont-search' />}
                  style={{ width: '80%', backgroundColor: 'white' }}
                  onChange={(event) => setZipValue(event.target.value)}
                />
              </Col>
            </Row>
          </Modal>

          {shouldShowLoadMore() && (
            <Button
              size='small'
              style={{
                backgroundColor: '#247',
                color: 'white',
                border: 'none',
                marginRight: '10px'
              }}
              onClick={() => {
                getData(loadMore + 1);
                setLoadMoreLoader(true);
              }}
            >
              {isLoadMoreLoading ? (
                <Space size='small'>
                  <Spin size='small' />
                </Space>
              ) : (
                ' Load More'
              )}
            </Button>
          )}
          <Button
            size='small'
            style={{
              backgroundColor: '#247',
              border: 'none'
            }}
            onClick={() => handleReset()}
          >
            Reset Filter
          </Button>
        </div>
      </div>

      <br />
      {(isLoadMoreLoading || isLoading) && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      {!nofound ? (
        tableData.length > 0 ? (
          <>

            <Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -20 }}>
              <Col style={{ fontSize: 15, fontWeight: 700 }}>
                {totalRecords ? `Total Records: ${totalRecords}` : null}
              </Col>
            </Row>

            <br />
            <div style={{ height: "330px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyCT1g5ZRhBfYQDYE_LuAFW5tT5U2vZJk88" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                {tableData &&
                  tableData.map((state, index) => {
                    const { clinic_name, street, city, state_id, partner_latitude, partner_longitude } = state;
                    return (
                      <Marker
                        key={index}
                        lat={partner_latitude}
                        lng={partner_longitude}
                        locationDetails={
                          <div>
                            <div style={{ fontWeight: 600 }}>{clinic_name}</div>
                            <div style={{ fontSize: 13 }}>{street}, {city}, {state_id}</div>
                          </div>
                        }
                      />
                    );
                  })}
              </GoogleMapReact>
            </div>

            <br />
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                {/* <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                /> */}
                <Search
                  placeholder='Search By Clinic Name'
                  enterButton
                  // loading={isLoading}
                  value={newClinic}
                  onSearch={onSearch}
                  onChange={handleChange}
                  name='clinicName'
                  size='small'
                // style={{ width: 304 }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 170 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                    getPopupContainer={() => fixedContainerRef.current}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                
                <Button
                  type='primary'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                  size='small'
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
                <Button
                  size='small'
                  style={{
                    backgroundColor: '#3d586a',
                    border: 'none',
                    float:"right",
                    marginRight: 10
                  }}
                  onClick={() => handleReset()}
                >
                  Reset Filter
                </Button>
              </Col>
            </Row>

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn
                binding='clinic_name'
                header='Clinic Legal Name'
                width='*'
                minWidth={400}
              // width={300}
              >
                <FlexGridCellTemplate cellType='Cell' template={showClinicName} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='emails_legacy'
                header='Authorization Email'
                width='*'
                minWidth={400}
              // width={300}
              >
                <FlexGridCellTemplate cellType='Cell' template={showAuthEmail} />
              </FlexGridColumn>

              {columns.map((column) => (
                <FlexGridColumn
                  header={column.header}
                  binding={column.field}
                  width={column.width}
                />
              ))}
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              className='cliniclisttable'
              alternatingRowStep={0}
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn
                binding='clinic_name'
                header='Clinic Legal Name'
                // width='*'
                // minWidth={290}
                width={260}
              // wordWrap={true}
              >
                <FlexGridCellTemplate cellType='Cell' template={showClinicName} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='emails_legacy'
                header='Authorization Email'
                // width='*'
                // minWidth={260}
                width={265}
              // wordWrap={true}
              >
                <FlexGridCellTemplate cellType='Cell' template={showAuthEmail} />
              </FlexGridColumn>
              <FlexGridColumn
                binding='phone'
                header='Phone Number'
                width={210}
              >
                <FlexGridCellTemplate cellType='Cell' template={showPhoneNumber} />
              </FlexGridColumn>


              {columns.map((column) => (
                <FlexGridColumn
                  header={column.header}
                  binding={column.field}
                  width={column.width}
                />
              ))}
              {/* <FlexGridColumn binding='actions' header='Actions' width={64}>
                <FlexGridCellTemplate cellType='Cell' template={showActions} />
              </FlexGridColumn> */}

              <FlexGridFilter
                showFilterIcons={true}
                placeholder='Filter data...'
                defaultFilterType='Value'
                filterColumns={['clinic_name', 'emails_legacy', 'phone', 'Type', 'city', 'zip', 'state_id']}
              />
            </FlexGrid>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                {Globalize.format(filterRefCounter.current, 'n0')}
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                {' '}
                out of {clinicList.length}
              </p>
            </div>
          </>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp; */}
        {errMsg}
      </p>
    </div>
  );
};

export default ClinicList;
