import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Spin, Space, Row, Col, Divider, List, Avatar, Card, Modal,Input, Tooltip,Table,Button } from 'antd';
import GoogleMapReact from 'google-map-react';
// import { GoogleMap, LoadScript, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
// import ReactEcharts from 'echarts-for-react';
import { history } from './../../redux/store';
// import AppointmentsTable from '../../../layout/components/appointmentsTable/AppointmentsTable';
// import hospitalOptions from './charts/hospital-options';
import axios from "axios";
import moment from 'moment';
import _ from 'underscore';
import setting from './../../config/setting'
import { CardHeader } from '@material-ui/core';
// import { incomeInWeek, incomeInMonth } from './charts/income-options';
// import Geocode from "react-geocode";
import { Tabs } from 'antd';

const { TabPane } = Tabs;




const Invoices = () => {



  return (
    JSON.parse(localStorage.getItem('customerData')).id == 269 ?
    <div>
<p style={{fontSize:18,fontWeight:700,padding:20}}>
      <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp;
      Module not activated!</p>
    </div>
     : 
     <p style={{fontSize:18,fontWeight:700,padding:20}}>
       <i className="icofont-exclamation-tringle" style={{color:'red',fontSize:20}}></i>&nbsp;&nbsp;
       Module not activated!</p>
  );
};



export default Invoices;